.navbar-brand svg {
    height: 3em;
}

.service-title {
    text-align: center;
    margin-top: 1em;
    font-size: 1.5em;
}

.michellPic {
    width: 100%;
}

#loading-cont {
    z-index: 9998;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
}

#loading-cont>div {
    margin: auto;
}

.form-check-label {
    float: right;
    margin-top: -1.3em;
    margin-left: 1em;
}